<script setup>
import blackArrows from '@/assets/img/ico/arrows-color2.svg'
import blueArrows from '@/assets/img/ico/arrows-color1.svg'
import grayArrows from '@/assets/img/ico/arrows-color4.svg'
import arrows from '@/assets/img/ico/arrows.svg'

defineProps({
  text: {
    type: String,
    default: '',
  },
  url: {
    type: String,
    default: '',
  },
  internalLink: {
    type: Boolean,
    default: false,
  },
  isBlack: {
    type: Boolean,
    default: false,
  },
  isBlue: {
    type: Boolean,
    default: false,
  },
  isGray: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <div class="main-button" :class="{ '--black': isBlack, '--blue': isBlue }">
    <nuxt-link
      v-if="internalLink"
      :to="
        localePath({
          path: url,
        })
      "
      class="text-4 main-button-container"
    >
      <div class="link">
        {{ text }}
      </div>
      <div class="icon-container">
        <div class="icon-link">
          <img
            :src="
              isBlack
                ? blackArrows
                : isBlue
                  ? blueArrows
                  : isGray
                    ? grayArrows
                    : arrows
            "
            alt="Icon Link"
          />
        </div>
      </div>
    </nuxt-link>
    <a
      v-else
      :href="url"
      target="_blank"
      rel="noopener noreferrer"
      class="text-4 main-button-container"
    >
      <div class="link">
        {{ text }}
      </div>
      <div class="icon-container">
        <div class="icon-link">
          <img
            :src="
              isBlack
                ? blackArrows
                : isBlue
                  ? blueArrows
                  : isGray
                    ? grayArrows
                    : arrows
            "
            alt="Icon Link"
          />
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'MainButton',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
